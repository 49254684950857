<div class="container-fluid text-center">
    <h2 class="text-right" style="margin-top:0px">{{ 'MostPopular' | translate}} </h2>
    <div class="row">
        <div class="col-md-6 padding5">
            <div class="article article-gray">
                <h3 class="text-center">{{ 'Clinics' | translate}}</h3>
                <div class="row">
                    <div class="col-md-6 padding-2">
                        <div class="alert alert-warning">
                            <h3 class="text-center">عيادة النيل</h3>
                            <div>
                                <img class="img-responsive img-rounded center-block"
                                     style="max-height:100px;"
                                     src="assets/images/Nile.png" />
                            </div>
                            <p>
                                <strong>
                                    التخصصات:
                                </strong>
                            </p>
                            <p>نساء وتوليد - جراحة عامة</p>
                                
                            <p>التليفون: 23545465656</p>
                            <p>العنوان: 20 شارع كذا، القاهرة</p>

                            <p>
                                <button class="btn btn-info" type="button">
                                    قراءة المزيد
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 padding-2">
                        <div class="alert alert-warning">
                            <h3 class="text-center">عيادة تبارك</h3>
                            <div>
                                <img class="img-responsive img-rounded center-block" style="max-height:100px;"
                                     src="assets/images/tabrak.png" />
                            </div>
                            <p>
                                <strong>
                                    التخصصات:
                                </strong>
                            </p>
                            <p>نساء وتوليد - أطفال</p>
                            
                            <p>التليفون: 23545465656</p>
                            <p>العنوان: 22 شارع كذا، الجيزه</p>
                            
                            <p>
                                <button class="btn btn-info" type="button">
                                    قراءة المزيد
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 padding-2">
                        <div class="alert alert-warning">
                            <h3 class="text-center">عيادة كليوباترا</h3>
                            <div>
                                <img class="img-responsive img-rounded center-block"
                                     style="max-height:100px;"
                                     src="assets/images/kleopatra.png" />
                            </div>
                            <p>
                                <strong>
                                    التخصصات:
                                </strong>
                            </p>
                            <p>باطنة - جلدية - أسنان - عيون</p>                            
                            <p>التليفون: 23545465656</p>
                            <p>العنوان: 111 شارع كذا، الجيزه</p>
                            
                            <p>
                                <button class="btn btn-info" type="button">
                                    قراءة المزيد
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 padding-2">
                        <div class="alert alert-warning">
                            <h3 class="text-center">عيادة القاهرة</h3>
                            <div>
                                <img class="img-responsive img-rounded center-block"
                                     style="max-height:100px;"
                                     src="assets/images/kahera.png" />
                            </div>
                            <p>
                                <strong>
                                    التخصصات:
                                </strong>
                            </p>
                            <p>باطنة - جلدية - أسنان - عيون - جراحة عامة</p>
                            <p>التليفون: 23545465656</p>
                            <p>العنوان: 125 شارع كذا، الفاهرة</p>                            
                            <p>
                                <button class="btn btn-info" type="button">
                                    قراءة المزيد
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 padding5">
            <div class="article article-gray">
                <h3 class="text-center">{{ 'Doctors' | translate}}</h3>
                <div class="row">
                    <div class="col-md-6">
                        <div class="alert alert-warning">
                            <div class="row">
                                <div class="col-md-3">
                                    <img class="img-responsive img-rounded center-block" style="width:100%;" title="الطبيب" ng-reflect-src="assets/images/vol-1.jpg" src="assets/images/vol-1.jpg">
                                </div>
                                <div class="col-md-9">
                                    <h3>أحمد علي محمد</h3>
                                </div>
                            </div>
                            <p>التخصص: أسنان</p>
                            <p>التليفون: 0899788675676</p>
                            <p>البريد: dr1@gmail.com</p>
                            <p>العنوان: 111 شارع، مدينة، دولة كذا</p>
                            <p>
                                <button class="btn btn-info" type="button">
                                    مشاهدة الملف الشخصى
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="alert alert-warning">
                            <div class="row">
                                <div class="col-md-3">
                                    <img class="img-responsive img-rounded center-block" style="width:100%;" title="الطبيب" ng-reflect-src="assets/images/vol-2.jpg" src="assets/images/vol-2.jpg">
                                </div>
                                <div class="col-md-9">
                                    <h3>سيد زكي إبراهيم</h3>
                                </div>
                            </div>
                            <p>التخصص: أطفال</p>
                            <p>التليفون: 3434354545</p>
                            <p>البريد: dr2@gmail.com</p>
                            <p>العنوان: 222 شارع، مدينة، دولة كذا</p>
                            <p>
                                <button class="btn btn-info" type="button">
                                    مشاهدة الملف الشخصى
                                </button>
                            </p>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="alert alert-warning">
                            <div class="row">
                                <div class="col-md-3">
                                    <img class="img-responsive img-rounded center-block" style="width:100%;" title="الطبيب" ng-reflect-src="assets/images/vol-3.jpg" src="assets/images/vol-3.jpg">
                                </div>
                                <div class="col-md-9">
                                    <h3>وائل جمعة رمضان</h3>
                                </div>
                            </div>
                            <p>التخصص: باطنة</p>
                            <p>التليفون: 7875645435</p>
                            <p>البريد: dr3@gmail.com</p>
                            <p>العنوان: 123 شارع، مدينة، دولة كذا</p>
                            <p>
                                <button class="btn btn-info" type="button">
                                    مشاهدة الملف الشخصى
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="alert alert-warning">
                            <div class="row">
                                <div class="col-md-3">
                                    <img class="img-responsive img-rounded center-block" style="width:100%;" title="الطبيب" ng-reflect-src="assets/images/vol-4.jpg" src="assets/images/vol-4.jpg">
                                </div>
                                <div class="col-md-9">
                                    <h3>عمرو كمال جاد</h3>
                                </div>
                            </div>
                            <p>التخصص: جراحة عامة</p>
                            <p>التليفون: 9786875645</p>
                            <p>البريد: dr4@gmail.com</p>
                            <p>العنوان: 333 شارع، مدينة، دولة كذا</p>
                            <p>
                                <button class="btn btn-info" type="button">
                                    مشاهدة الملف الشخصى
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>