<form [formGroup]="visitorForm" novalidate>
  <!-- <div class="form-group position-relative">
    <input type="email" class="form-control" formControlName="Email" />
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-at" width="24" height="24"
      viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="4" />
      <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
    </svg>
  </div>
  <div *ngIf="
        visitorForm.controls['Email'].invalid &&
        (visitorForm.controls['Email'].dirty || visitorForm.controls['Email'].touched)
      " class="alert alert-danger">
    <div *ngIf="visitorForm.controls['Email'].hasError('required')">
      {{ "login.reqEmail" | translate }}
    </div>
  </div>
  <div class="form-group position-relative">
    <input type="password" class="form-control" formControlName="Password" />
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock-open" width="24" height="24"
      viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round"
      stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="5" y="11" width="14" height="10" rx="2" />
      <circle cx="12" cy="16" r="1" />
      <path d="M8 11v-5a4 4 0 0 1 8 0" />
    </svg>
  </div>
  <div *ngIf="
      visitorForm.controls['Password'].invalid &&
      (visitorForm.controls['Password'].dirty || visitorForm.controls['Password'].touched)
    " class="alert alert-danger">
    <div *ngIf="visitorForm.controls['Password'].hasError('required')">
      {{ "login.reqPass" | translate }}
    </div>
  </div>
  <button class="btn btn-primary" type="submit" [disabled]='!visitorForm.valid' (click)="login()">
    {{ "login.signIn" | translate }}
  </button> -->
  <div class="col-lg-12 col-md-12 "style="text-align: center;">
    <h2 style="color: white;">{{"subscrible.h2"| translate}}  {{"subscrible.br"| translate}}  </h2> 
    <a class="col-md-6" target="_blank" href="https://play.google.com/store/apps/details?id=com.connclinic.app.flutter"> <img src="assets/img/iconfinder_android-app-on-google-play_720092.png" alt="HTML tutorial" style="width:180px;height:180px;border:0;margin-top: -60px;">
                </a>
     <a class="col-md-6" target="_blank" href="https://apps.apple.com/app/id1592923851"><img src="assets/img/appstore.png" alt="HTML tutorial" style="width:170px;height:55px;border:0;"></a>
</div>

</form>