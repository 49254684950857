<section class="featured">
    <div class="container">
        <h2 class="text-center"> {{"blogs.head"| translate}} </h2>
        <p class="text-center mb-5"> {{"blogs.desc"| translate}} </p>
        <div class="row" *ngIf="lang == 'ar'">
            <!-- first course -->
            <div *ngFor="let blog of arabicBlogs" class="col-lg-4 col-md-6 col-sm-12">
                <div class="card">
                    <img class="card-img-top" src={{blog.img}} alt="Card image cap">
                    <div class="card-body">
                        <img src={{blog.subImg}} alt="">
                        <a routerLink="/blogs/{{blog.id}}" class="card-title"> {{blog.title}} </a>
                        <p class="card-text"> {{blog.text}} </p>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row" *ngIf=" lang == 'en'">
            <!-- first course -->
            <div *ngFor="let blog of blogs" class="col-lg-4 col-md-6 col-sm-12">
                <div class="card">
                    <img class="card-img-top" src={{blog.img}} alt="Card image cap">
                    <div class="card-body">
                        <img src={{blog.subImg}} alt="">
                        <a routerLink="/blogs/{{blog.id}}" class="card-title"> {{blog.title}} </a>
                        <p class="card-text"> {{blog.text}} </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>