<br />
<br />
<br />
<div class="row">
    <div class="col-md-offset-2 col-md-8">
        <div class="alert alert-danger">
            <strong>{{ 'AccessRight' | translate}}</strong>

            <br />
            <br />
            <a routerLink="/home" class="alert-link">{{ 'GoHome' | translate}}</a>
        </div>
    </div>
</div>
