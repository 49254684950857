<fieldset>
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <legend>
                <h3>{{ 'ReportsAndIndicators' | translate}}</h3>
            </legend>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-md-offset-3">
            <h4>
                <a href="#">
                   {{ 'PatientHistoryReport' | translate}}
                </a>
            </h4>
        </div>
        <div class="col-md-3">
            <h4>
                <a href="#">
                    {{ 'ReportVsitsToDoctor' | translate}}                </a>
            </h4>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-3 col-md-offset-3">
            <h4>
                <a href="#">
                    {{ 'ReservationReportForClinic' | translate}}
                </a>
            </h4>
        </div>
        <div class="col-md-3">
            <h4>
                <a href="#">
                    {{ 'PerformanceIndicatorsReport' | translate}}
                </a>
            </h4>
        </div>
    </div>
    <br />
</fieldset>