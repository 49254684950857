import {Component} from '@angular/core';

@Component({
   
    selector: 'unauthorized',
    templateUrl: 'unauthorized.component.html'

})

export class UnAuthorizedComponent {



}