<section class="contact">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 left-pic">
                <img src="assets/img/contact.png" alt="">
            </div>
            <div class="col-lg-6 col-md-12 right-contact">
                <span>{{"subscrible.subscribleNow"| translate}}</span>
                <h2>{{"subscrible.h2"| translate}} <br> {{"subscrible.br"| translate}}  </h2> 
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.connclinic.app.flutter"> <img src="assets/img/iconfinder_android-app-on-google-play_720092.png" alt="HTML tutorial" style="width:180px;height:180px;border:0;">
                            </a>
                            <a target="_blank" href="https://apps.apple.com/app/id1592923851"><img src="assets/img/appstore.png" alt="HTML tutorial" style="width:180px;height:55px;border:0;"></a>
            </div>

        </div>
    </div>
</section>