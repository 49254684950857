import {Component} from '@angular/core';

@Component({
    selector: 'reports',
    templateUrl: './reports.component.html'

})

export class ReportsComponent {
    
}