<div class="left-aside desktop-view">
    <div class="aside-branding">
        <a>
            <!-- <img src="assets/images/App-Logo3.png" title="Logo" class="appLogo" /> -->
        </a>
        <span class="aside-pin waves-effect"><i class="fa fa-thumb-tack"></i></span>
        <span class="aside-close waves-effect" onclick="closeLeftBar();"><i class="fa fa-times"></i></span>
    </div>
    <div class="left-navigation">
        <ul class="list-accordion padding5" id="listTo">
            <li>
                <a class="{{ (activeTabIndex == 2) ? 'selectednavbaritem active' : '' }}" *ngIf="isDoctor"
                    routerLink="/doctor/address/{{doctorId}}">
                    <span class="nav-icon"><i class="ico-info-with-circle"></i></span><span class="nav-label">{{
                        'AddressandContact' | translate}}</span>
                </a>
            </li>
            <li>
                <a class="{{ (activeTabIndex == 3) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/doctor/appointments" *ngIf="isDoctor && hasEMR">
                    <span class="nav-icon"><i class="fa fa-calendar"></i></span><span class="nav-label">{{
                        'MyAppointments' | translate}}</span>
                </a>
            </li>
            <li>
                <a class="{{ (activeTabIndex == 4) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/doctor/requests/{{doctorId}}" *ngIf="enableDoctorInventoryRequestsLink">
                    <span class="nav-icon"><i class="ico-download"></i></span><span class="nav-label">{{
                        'InventoryRequests' | translate}}</span>
                </a>
            </li>
            <!-- <li>
                <a class="{{ (activeTabIndex == 5) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/doctor/reports/{{doctorId}}" *ngIf="isDoctor">
                    <span class="nav-icon"><i class="fa fa-files-o"></i></span><span class="nav-label">{{ 'Reports' |
                        translate}}</span>
                </a>
            </li> -->

            <li>
                <a class="{{ (activeTabIndex == 8) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/receptionist/address/{{receptionistId}}" *ngIf="isReceptionist">
                    <span class="nav-icon"><i class="ico-info-with-circle"></i></span><span class="nav-label">{{
                        'AddressContact' | translate}}</span>
                </a>
            </li>

            <li *ngIf="(enableReceptionistAppointmentsLink || enableAppointmentMasterLink) && hasAppointments">
                <a onclick="applyVerticalMenuStyle(this);">
                    <span class="nav-icon"><i class="fa fa-calendar"></i></span><span class="nav-label">{{
                        'Appointments' | translate}}</span>
                    <span class="menuarrow glyphicon glyphicon-chevron-right"></span>
                </a>
                <ul>
                    <li>
                        <a class="{{ (activeTabIndex == 9) ? 'selectednavbaritem active' : '' }}"
                            routerLink="/receptionist/appointments"
                            *ngIf="enableReceptionistAppointmentsLink && hasAppointments">
                            <span class="nav-icon"><i class="fa fa-dot-circle-o"></i></span><span class="nav-label">{{
                                'Appointments' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ isAppointmentsSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/appointments/{{clinicId}}"
                            *ngIf="enableAppointmentMasterLink && hasAppointments">
                            <span class="nav-icon"><i class="fa fa-dot-circle-o"></i></span><span class="nav-label">{{
                                'AppointmentMaster' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li>
                <a class="{{ (activeTabIndex == 10) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/receptionist/patients/{{receptionistId}}"
                    *ngIf="enableReceptionistPatientsLink && hasPatientRegistration">
                    <span class="nav-icon"><i class="fa fa-hotel"></i></span><span class="nav-label">{{ 'Patients' |
                        translate}}</span>
                </a>
            </li>
            <li>
                <a class="{{ (activeTabIndex == 11) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/receptionist/requests/{{receptionistId}}"
                    *ngIf="enableReceptionistRequestsLink && hasPatientRegistration">
                    <span class="nav-icon"><i class="ico-users-more"></i></span><span class="nav-label">{{
                        'GuestRequests' | translate}}</span>
                </a>
            </li>
            <li>
                <a class="{{ (activeTabIndex == 12) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/receptionist/reports/{{receptionistId}}" *ngIf="isReceptionist">
                    <span class="nav-icon"><i class="fa fa-files-o"></i></span><span class="nav-label">{{ 'Reports' |
                        translate}}</span>
                </a>
            </li>

            <li>
                <a class="{{ (activeTabIndex == 14) ? 'selectednavbaritem active' : '' }}" routerLink="/cashier/claims"
                    *ngIf="hasClaimDetails && hasBillingAndInsurance">
                    <span class="nav-icon"><i class="fa fa-dollar"></i></span><span class="nav-label">{{ 'Claims' |
                        translate}}</span>
                </a>
            </li>
            <li>
                <a class="{{ (activeTabIndex == 15) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/cashier/patientTransactions" *ngIf="hasClaimDetails && hasBillingAndInsurance">
                    <span class="nav-icon"><i class="fa fa-money"></i></span><span class="nav-label">{{
                        'PatientTransactions' | translate}}</span>
                </a>
            </li>
            <li>
                <a class="{{ (activeTabIndex == 16) ? 'selectednavbaritem active' : '' }}"
                    routerLink="/cashier/reports/1" *ngIf="isCashier">
                    <span class="nav-icon"><i class="fa fa-files-o"></i></span><span class="nav-label">{{ 'Reports' |
                        translate}}</span>
                </a>
            </li>
            <!-- Invoice  shared tabs-->
            <li
                *ngIf="(hasAccessTransacton || hasAccessPolicies || hasAccessInvoice || hasAccessGenerateInvoices) && hasBillingAndInsurance">
                <a onclick="applyVerticalMenuStyle(this);">
                    <span class="nav-icon"><i class="fa fa-bank"></i></span><span class="nav-label">{{
                        'InsuranceCompanies' | translate}}</span>
                    <span class="menuarrow glyphicon glyphicon-chevron-right"></span>
                </a>

                <ul>
                    <li>
                        <a class="{{ (activeTabIndex == 17) ? 'active' : '' }}" routerLink="/insurance/companies"
                            *ngIf="hasAccessPolicies">
                            <span class="nav-icon"><i class="fa fa-bank"></i></span><span class="nav-label">{{
                                'InsuranceCompanies' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ (activeTabIndex == 18) ? 'active' : '' }}"
                            routerLink="/insurance/companyTransactions" *ngIf="hasAccessTransacton">
                            <span class="nav-icon"><i class="fa fa-exchange"></i></span><span class="nav-label">{{
                                'CompanyTransactions' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ (activeTabIndex == 19) ? 'active' : '' }}" routerLink="/insurance/coverageDetails"
                            *ngIf="hasAccessTransacton">
                            <span class="nav-icon"><i class="fa fa-dot-circle-o"></i></span><span class="nav-label">{{
                                'CoverageDetail' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ (activeTabIndex == 20) ? 'active' : '' }}" *ngIf="hasAccessGenerateInvoices"
                            routerLink="/insurance/invoice/generate">
                            <span class="nav-icon"><i class="fa fa-dot-circle-o"></i></span><span class="nav-label">{{
                                'GenerateInvoices' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ (activeTabIndex == 21) ? 'active' : '' }}" *ngIf="hasAccessInvoice"
                            routerLink="/insurance/invoice">
                            <span class="nav-icon"><i class="fa fa-file-text-o"></i></span><span class="nav-label">{{
                                'Invoices' | translate}}</span>
                        </a>
                    </li>
                  
                </ul>
            </li>

            <!-- clinic admin shared tabs-->

            <li>
                <a class="{{ isOrgMasterSelected ? 'selectednavbaritem active' : '' }}"
                    routerLink="/clinic/organizationMaster/{{clinicId}}"
                    *ngIf="enableOrganizationStructureLink && hasClinicMaster">
                    <span class="nav-icon"><i class="fa fa-sitemap"></i></span><span class="nav-label">{{
                        'OrganizationStructure' | translate}}</span>
                </a>
            </li>

            <li *ngIf="enableCodingLinks && hasCoding">
                <a onclick="applyVerticalMenuStyle(this);">
                    <span class="nav-icon"><i class="fa fa-barcode"></i></span><span class="nav-label">{{ 'Coding' |
                        translate}}</span>
                    <span class="menuarrow glyphicon glyphicon-chevron-right"></span>
                </a>

                <ul>
                    <li>
                        <a class="{{ isDiagnosisSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/diagnosis/{{clinicId}}" *ngIf="enableCodingLinks">
                            <span class="nav-icon"><i class="fa fa-stethoscope"></i></span><span class="nav-label">{{
                                'Diagnosis' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ isDrugsSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/drugs/{{clinicId}}" *ngIf="enableCodingLinks">
                            <span class="nav-icon"><i class="fa fa-glass"></i></span><span class="nav-label">{{ 'Drugs'
                                | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ isLaboratoryTestsSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/laboratoryTests/{{clinicId}}" *ngIf="enableCodingLinks">
                            <span class="nav-icon"><i class="fa fa-flask"></i></span><span class="nav-label">{{
                                'LaboratoryTests' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ isRadiologyTestsSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/radiologyTests/{{clinicId}}" *ngIf="enableCodingLinks">
                            <span class="nav-icon"><i class="fa fa-bed"></i></span><span class="nav-label">{{
                                'RadiologyTests' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ isServicesSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/services/{{clinicId}}" *ngIf="enableCodingLinks">
                            <span class="nav-icon"><i class="fa fa-heartbeat"></i></span><span class="nav-label">{{
                                'Services' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ isServicePackagesSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/servicePackages/{{clinicId}}" *ngIf="enableCodingLinks">
                            <span class="nav-icon"><i class="fa fa-heartbeat"></i></span><span class="nav-label">{{
                                'ServicePackage' | translate}}</span>
                        </a>
                    </li>
                    <li>
                        <a class="{{ isProductsSelected ? 'selectednavbaritem active' : '' }}"
                            routerLink="/clinic/products/{{clinicId}}" *ngIf="enableCodingLinks">
                            <span class="nav-icon"><i class="fa fa-eyedropper"></i></span><span class="nav-label">{{
                                'Products' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li>
                <a class="{{ isEMRMasterSelected ? 'selectednavbaritem active' : '' }}"
                    routerLink="/clinic/EMRMaster/{{clinicId}}" *ngIf="enableEmrMasterLink && hasEMR">
                    <span class="nav-icon"><i class="ico-flow-cascade"></i></span><span class="nav-label">{{ 'EMRMaster'
                        | translate}}</span>
                </a>
            </li>

            <li>
                <a class="{{ isInventorySelected ? 'selectednavbaritem active' : '' }}"
                    routerLink="/clinic/inventory/{{clinicId}}"
                    *ngIf="enableInventoryManagementLink && hasPurshasingAndInventory">
                    <span class="nav-icon"><i class="ico-shop"></i></span><span class="nav-label">{{ 'Inventory' |
                        translate}}</span>
                </a>
            </li>
            <li>
                <a class="{{ isPurchasingSelected ? 'selectednavbaritem active' : '' }}"
                    routerLink="/clinic/purchasing/all/{{clinicId}}"
                    *ngIf="enablePurchaseManagementLink && hasPurshasingAndInventory">
                    <span class="nav-icon"><i class="fa fa-shopping-cart"></i></span><span class="nav-label">{{
                        'Purchasing' | translate}}</span>
                </a>
            </li>

        </ul>
    </div>
</div>