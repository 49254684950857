import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExternalUsersRoutingModule } from './external-users-routing.module';
import { UserComponent } from './user/user.component';
import { AddUserComponent } from './add-user/add-user.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    UserComponent,
    AddUserComponent
  ],
  imports: [
    CommonModule,
    ExternalUsersRoutingModule,
    ReactiveFormsModule,
  ]
})
export class ExternalUsersModule { }
