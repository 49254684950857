import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LangChangeEvent,  TranslateService } from '@ngx-translate/core';

import { Meta} from "@angular/platform-browser";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  
  currentLang: string;

  constructor(private sharedService:SharedService , private meta:Meta,public translate :TranslateService) {
    // if you find any thing in localStorage => return it && if not return EN
    this.currentLang = localStorage.getItem('currentLang') || 'en';
    // use the returned lang value
    this.translate.use(this.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('HomeDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('HomeTitle'))
  })
  }
  // change the current lang Function
  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('currentLang', lang);
  }

  ngOnInit(): void {
  
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('HomeDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('HomeTitle'))
  
  }
}
