<div class="card d-flex justify-content-center  border-0">
    <div class="card-body">
        <div class="">
            <div class="row d-flex justify-content-center ">
                <h2 class="text-center header-section" style="font-size: 4rem !important;">
                    سجل بياناتك الآن                </h2>
            </div>
            <div class="container-fluid">
                <div class="card  border-0">
                    <div class="card-body">
                        <form [formGroup]="LandingForm" novalidate >
                            <div class="right mb-4">
                                <div class="mb-3 mt-3 form-group right">
                                    <label for="FName" class="form-label input-lable"  style=" color: #666666 !important;
                                    font-family: Lato !important;
                                 font-size: 2rem !important;">
                                        الاسم الأول*</label>
                                    <div class="input ">
                                        <input type="text" formControlName="FName" class="form-control"
                                            id ="FName">
                                    </div>
                                 
                                </div>
                                <div *ngIf="
                                LandingForm.controls['FName'].invalid &&
                                  (LandingForm.controls['FName'].dirty || LandingForm.controls['FName'].touched)
                                " class="alert alert-danger">
                                                                    <div *ngIf="LandingForm.controls['FName'].hasError('required')">
                                                                        Please complete this required field.
                                                                    </div>
                                                                </div>
                                <div class="mb-3 mt-3 form-group right">
                                    <label for="LName" class="form-label right input-lable" style=" color: #666666 !important;
                                    font-family: Lato !important;
                                 font-size: 2rem !important;" >
                                        اسم العائلة*</label>
                                    <input type="text" formControlName="LName" class="form-control" id="LName"
                                        >
                                </div>
                                <div *ngIf="
                                LandingForm.controls['LName'].invalid &&
                                  (LandingForm.controls['LName'].dirty || LandingForm.controls['LName'].touched)
                                " class="alert alert-danger">
                                                                    <div *ngIf="LandingForm.controls['LName'].hasError('required')">
                                                                        Please complete this required field.
                                                                    </div>
                                                                </div>

                                <div class="mb-3 mt-3 form-group right">
                                    <label for="Email" class="form-label right input-lable" style=" color: #666666 !important;
                                    font-family: Lato !important;
                                 font-size: 2rem !important;">
                                        البريد الإلكتروني*</label>
                                    <input type="email"formControlName="Email" class="form-control" id="Email">
                                </div>
                                <div *ngIf="
                                LandingForm.controls['Email'].invalid &&
                                  (LandingForm.controls['Email'].dirty || LandingForm.controls['Email'].touched)
                                " class="alert alert-danger">
                                                                    <div *ngIf="LandingForm.controls['Email'].hasError('required')">
                                                                        Please complete this required field.
                                                                    </div>
                                                                </div>
                                <div class="mb-3 mt-3 form-group right">
                                    <label for="Phone" class="form-label right input-lable" style=" color: #666666 !important;
                                    font-family: Lato !important;
                                 font-size: 2rem !important;">
                                        
                                        رقم تليفون واتساب*</label>
                                    <input type="tel" formControlName="Phone" class="form-control" id="Phone">
                                </div>
                                <div *ngIf="
                                LandingForm.controls['Phone'].invalid &&
                                  (LandingForm.controls['Phone'].dirty || LandingForm.controls['Phone'].touched)
                                " class="alert alert-danger">
                                                                    <div *ngIf="LandingForm.controls['Phone'].hasError('required')">
                                                                        Please complete this required field.
                                                                    </div>
                                                                </div>
                                <div class="mb-3 mt-3 form-group right">
                                    <label for="ClinicName"  class="form-label text-right input-lable" style=" color: #666666 !important;
                                    font-family: Lato !important;
                                 font-size: 2rem !important;" >اسم
                                        العيادة*</label>
                                    <input type="text" formControlName="ClinicName" class="form-control" id="ClinicName">
                                </div>
                                <div *ngIf="
                                LandingForm.controls['ClinicName'].invalid &&
                                  (LandingForm.controls['ClinicName'].dirty || LandingForm.controls['ClinicName'].touched)
                                " class="alert alert-danger">
                                                                    <div *ngIf="LandingForm.controls['ClinicName'].hasError('required')">
                                                                        Please complete this required field.
                                                                    </div>
                                                                </div>
                                <div class="">
                                    <button type="submit" [disabled]='!LandingForm.valid' (click)="AddUserLandingPage()" class="btn mt-3 btn-success btn-lg text-right "
                                        style="width:130px; height: 50px;"> <span
                                            class="text-center d-flex justify-content-center">
                                            ارسال</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>