import { Component, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'layout-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})

export class NavBarComponent implements OnInit {
  currentLang: string;
  myLang:string
  constructor(public translate: TranslateService) {
    // if you find any thing in localStorage => return it && if not return EN
    this.currentLang = localStorage.getItem('currentLang') || 'en';
    this.myLang = localStorage.getItem('currentLang') || 'en';
    // use the returned lang value
    this.translate.use(this.currentLang);
  }
  // change the current lang Function
  changeLang(lang: string) {
    this.myLang = lang ;
    this.translate.use(this.myLang);
    localStorage.setItem('currentLang', this.myLang);
    console.log(this.myLang);
    location.reload();

  }

  ngOnInit(): void { }

  showNavbarSupportedContent() {
    
    var element: any = document.getElementById("navbarSupportedContent");
    if (element && !element.classList.contains("show"))
      element.classList.add('show');
    else{
      element.classList.remove('show');
    }
  }

}
