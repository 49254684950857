<fieldset>
    <div class="row">
        <div class="col-md-offset-1 col-md-10">
            <legend>
                <h3>{{ 'AdvancedSearchFor' | translate}}</h3>
            </legend>
        </div>
    </div>
    <div class="row">
        <div class="col-md-offset-1 col-md-10">
            <ul class="nav nav-tabs">
                
                <li class="active"><a href="#clinics" data-toggle="tab" aria-expanded="true">{{ 'Clinics' | translate}}</a></li>
                <li><a href="#doctors" data-toggle="tab" aria-expanded="true">{{ 'Doctors' | translate}}</a></li>
                
            </ul>
            <div id="myTabContent" class="tab-content">
                
                <div class="tab-pane fade active in" id="clinics">
                    <!--<doctor-advanced-search></doctor-advanced-search>-->
                </div>
                <div class="tab-pane fade" id="doctors">
                    <!--<doctor-advanced-search></doctor-advanced-search>-->
                </div>
                
            </div>
        </div>
    </div>
</fieldset>


