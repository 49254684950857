<section class="featured">
    <div class="container">
            <!-- first course -->
                <div class="card" *ngIf="lang == 'en'">
                    <img class="card-img-top" src={{blogs[id].img}} alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title"> {{blogs[id].title}} </h5>
                        <p class="card-text"> {{blogs[id].text}} </p>
                        <p class="card-text"> {{blogs[id].text1}} </p>
                        <p class="card-text"> {{blogs[id].text2}} </p>
                        <p class="card-text"> {{blogs[id].text3}} </p>
                        <p class="card-text"> {{blogs[id].text4}} </p>
                        <p class="card-text"> {{blogs[id].text5}} </p>
                        <p class="card-text"> {{blogs[id].text6}} </p>
                        <p class="card-text"> {{blogs[id].text7}} </p>
                        <p class="card-text"> {{blogs[id].text8}} </p>
                        <p class="card-text"> {{blogs[id].text9}} </p>
                        <p class="card-text"> {{blogs[id].text10}} </p>
                        <p class="card-text"> {{blogs[id].text11}} </p>
                        <p class="card-text"> {{blogs[id].text12}} </p>
                        <p class="card-text"> {{blogs[id].text13}} </p>
                        <p class="card-text"> {{blogs[id].text14}} </p>
                        <p class="card-text"> {{blogs[id].text15}} </p>
                        <p class="card-text"> {{blogs[id].text16}} </p>
                        <p class="card-text"> {{blogs[id].text17}} </p>
                        <p class="card-text"> {{blogs[id].text18}} </p>
                        <p class="card-text"> {{blogs[id].text19}} </p>
                    </div>
                </div>
                <div class="card" *ngIf="lang == 'ar'">
                    <img class="card-img-top" src={{arabicBlogs[id].img}} alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title"> {{arabicBlogs[id].title}} </h5>
                        <p class="card-text"> {{arabicBlogs[id].text}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text1}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text2}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text3}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text4}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text5}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text6}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text7}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text8}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text9}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text10}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text11}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text12}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text13}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text14}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text15}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text16}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text17}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text18}} </p>
                        <p class="card-text"> {{arabicBlogs[id].text19}} </p>
                    </div>
                </div>
    </div>
</section>