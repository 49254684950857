import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss'],
})
export class WhatWeDoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
