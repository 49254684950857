<div class="">
    <section name="nav-bar" class="nav-bar background-color-White">
        <div class="d-flex justify-content-center">
            <div class="justify-content-center mt-3 mb-4">
                <img
                    src="https://26965690.fs1.hubspotusercontent-eu1.net/hub/26965690/hubfs/new-02.png?width=106&name=new-02.png" />
            </div>
        </div>
    </section>
    <section class="container-fluid ">

        <div class="body-wrapper bg-light">


            <div class=" container-fluid">
                <div class="col-md-6 mt-4 justify-content-center box">
                    <div class="card bg-light border-0">
                        <div class="mt-4">
                            <div class="row justify-content-center ">
                                <div class="justify-content-center">
                                    <div class="center d-flex justify-content-center">
                                        <h1 class="w-200 center text-center header-section" >
                                            قم بإدارة عيادتك بسهولة
                                            ودقه من أي مكان وكل وقت</h1>
                                    </div>

                                </div>

                                <div class=" container-fluid">
                                    <div class="card p-5 bg-light justify-content-center border-0">

                                        <div class="card-body">
                                            <!-- <div class="card-title text-center mb-4 ">
                                                <h3 class="w-200 center text-muted" style="  ">
                                                    قم بإدارة عيادتك بسهولة
                                                    ودقه من أي مكان وكل وقت
                                                </h3>
                                            </div> -->
                                            <div class="container-fluid">
                                                <img class="p-4" src="../../../assets/images/formImg.png" width="100%"
                                                    height="80%" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 mt-4 vh-100 d-flex align-items-center justify-content-center">
                   
                    <div class="card border-0 bg-light">
                        <div class="card-body">
                            <div class="container d-flex justify-content-center ">
                                <div class="justify-content-center">
                                    <h1 class="text-center p-5 header-section">
                                        إدارة العيادات أسهل مع كونكلنك                                    </h1>
                                    <p class="Card-text-section p-5 " style="font-size: 2rem!important;">
                                        نظام سحابي يمكنك من خلاله إدارة الحجوزات والتعامل مع السجلات الطبية للمرضى وإعداد التقارير وأيضا المدفوعات المباشرة والتعامل مع شركات التأمين وغيرها من الخصائص المختلفة.</p>
                                        <div class="d-flex  align-items-center justify-content-center">
<a class="btn btn-primary " onclick="document.getElementById('registeration').scrollIntoView();" >سجل الأن</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section id="registeration">
        <div class="container nav-bar background-color-White">
            <div class="text-center mt-5 d-flex justify-content-center">
                <h1 class="header-section">
                    لماذا كونكلنك؟</h1>

            </div>
        </div>
        <div class="container mt-5">
            <div class="row d-flex justify-content-center">
                <div class=" row-fluid">
                    <div class="col-sm-6">
                        <div class="card  border-0">
                            <div class="card-body">
                                <img src="../../../assets/images/Conclinc-Dec-D5.png" width="100%" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-4 justify-content-center">
                        <app-add-user></app-add-user>
                       
                    </div>
                </div>


            </div>
        </div>




    </section>
    <section class="p-4">
        <div class="container nav-bar background-color-White">
            <div class="text-center mt-5 d-flex justify-content-center">
                <h1 class="header-section">
                </h1>

            </div>
        </div>
        <div class="container mt-5">
            <div class="row d-flex justify-content-center">
                <div class=" row-fluid">
                    <div class="col-md-4">
                        <div class="card text-center border-0">
                            <img src="../../../assets/images/Conclinc-Dec-D2.png" class="card-img-top p-2" alt="...">
                            <div class="card-body">
                                <div class=" card-title">
                                    <h2 class="text-center"
                                        style=" font-size: 3rem !important;  width:100% imo !important">
                                إدارة الحجوزات                                    </h2>
                                </div>
                                <p class="card-text Card-text-section" >

                                    يساعدك نظام كونكلنك على تسجيل وجدولة مواعيد المرضى إلكترونيًا مع تحديد مدة كل جلسة، وإظهار الحجوزات بشكل دوري باليوم أو الأسبوع أو الشهر مع خاصية التأكيد والإلغاء لكل حجز.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-center border-0">
                            <img src="../../../assets/images/Conclinc-Dec-D1.png" class="card-img-top p-2" alt="...">
                            <div class="card-body">
                                <div class=" card-title">
                                    <h2 class="text-center header-section"
                                        style=" font-size: 3rem !important;    width:100% !important">
                                        ربط العيادات</h2>
                                </div>
                                <p class="card-text Card-text-section">
                                    يُمكنك نظام كونكلنك من إدارة كل عياداتك من مكان واحد، من خلال لوحة تحكم رئيسية تظهر بها المعلومات المتعلقة بإدارة نشاطك، مثل المواعيد والحجوزات الحالية والسابقة، والمدفوعات الحالية أو السابقة، بالإضافة إلى عدد الأطباء المتاحين في فترة زمنية محددة وغيرها.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-center border-0">
                            <img src="../../../assets/images/Conclinc-Dec-D8.png" class="card-img-top p-2" alt="...">
                            <div class="card-body">
                                <div class=" card-title">
                                    <h2 class="text-center header-section"
                                        style=" font-size: 3rem !important;   width:100% !important">
                                        إدارة مخزن الأدوات الطبية
                                    </h2>
                                </div>
                                <p class="card-text Card-text-section" >
                                    نظام كونكلنك يُسهل لك إدارة مخزن المستلزمات الطبية المختلفة، حيث يتيح لك البرنامج التفريغ التلقائي لأوامر الشراء وربطها بالمخزن الحالي وربط طلبات العيادات من مستلزمات، والإطلاع عليها حين الطلب
                                </p>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>




    </section>
    <section class="p-4">
        <div class="container nav-bar background-color-White">
            <div class="text-center mt-5 d-flex justify-content-center">
                <h1 class="header-section">
                </h1>

            </div>
        </div>
        <div class="container mt-5">
            <div class="row d-flex justify-content-center">
                <div class=" row-fluid">
                    <div class="col-md-4">
                        <div class="card text-center border-0">
                            <img src="../../../assets/images/6-01.jpg" class="card-img-top p-2" alt="...">
                            <div class="card-body">
                                <div class=" card-title">
                                    <h2 class="text-center header-section"
                                        style=" font-size: 3rem !important;  width:100% !important">
                                        ملف لكل مريض</h2>
                                </div>
                                <p class="card-text Card-text-section" >
                                    كونكلنك يتيح لك تسجيل واسترجاع التاريخ المرضي الكامل للمريض مع الإلتزام بخصوصية المرضى، ويشمل ملف كل مريض على عدة نقاط أساسية مثل: المؤشرات الحيوية، والأشعة والتحاليل، ومواعيد الزيارات السابقة، وأي كشوفات سابقة في عيادات أخرى وغيرها.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-center border-0">
                            <img src="../../../assets/images/5-01.jpg" class="card-img-top p-2" alt="...">
                            <div class="card-body">
                                <div class=" card-title">
                                    <h2 class="text-center"
                                        style=" font-size: 3rem;  font-family: Merriweather; font-weight: 600; color: #494a52;  width:100%">
                                        التعاقدات التأمينية</h2>
                                </div>
                                <p class="card-text Card-text-section">نظام كونكلنك يدمج تفاصيل المريض مع شركة التأمين مما يسهل عليك سهولة الوصول للبوليصة
                                    التأمينية الخاصة بالمريض وتسجيل الفواتير المصدق عليها.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card text-center border-0">
                            <img src="../../../assets/images/Conclinc-Dec-D42.png" class="card-img-top p-2" alt="...">
                            <div class="card-body">
                                <div class=" card-title">
                                    <h2 class="text-center"
                                        style=" font-size: 3rem;  font-family: Merriweather; font-weight: 600; color: #494a52;  width:100%">
                                        قاعدة بيانات متكاملة</h2>
                                </div>
                                <p class="card-text Card-text-section" >
                                    كونكلنك نظام سحابي يتيح لك تسجيل واستخراج كل البيانات اللازمة لإدارة نشاطك من أي جهاز متصل بالإنترنت، يمكنك استخراج تقارير أداء العيادة وملفات المرضى والحجوزات المختلفة. كما يتيح لك أيضًا  التحكم الكامل في صلاحيات المستخدمين لضمان خصوصية البيانات.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>




    </section>
    <section class="pt-3">
        <div class="container nav-bar background-color-White">
            <div class="text-center mt-5 d-flex justify-content-center">
                <h1 class="header-section">
                    اعرف أكثر عن كونكلنك                </h1>

            </div>
        </div>
        <div class="container mt-5">
            <div class="row d-flex justify-content-center">
                <div class=" row-fluid">
                    <div class="col-sm-6">
                        <div class="card  border-0">
                            <div class="card-body">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/LH7houG9kjY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                             
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>




    </section>

    <section class="bg-light mt-5 pt-5">
        <div class="container nav-bar background-color-White bg-light">
            <div class="text-center mt-5 d-flex justify-content-center">
                <h1 class="header-section">
                    شركاء النجاح</h1>

            </div>
        </div>
        <div class="container mt-5">
            <div class="row d-flex justify-content-center">
                <div class=" row-fluid col-sm-12">

                    <div class="col-md-3">
                        <div class="card  border-0">
                            <div class="card-body">
                                <img src="../../../assets/images/arafa.jpg" width="100%" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card  border-0">
                            <div class="card-body">
                                <img src="../../../assets/images/Nourin.jpeg" width="100%" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card  border-0">
                            <div class="card-body">
                                <img src="../../../assets/images/modaoa.png" width="100%" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card  border-0">
                            <div class="card-body">
                                <img src="../../../assets/images/DrAhmed Saeed.png" width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-sm-12 d-flex justify-content-center">
                    <div class="wrapper text-center">
                        <a href="https://www.facebook.com/conclinic.business" target="_blank" class="icon facebook">
                          <div class="tooltip">Facebook</div>
                          <span><i class="fa fa-facebook-f"></i></span>
                        </a>
                        <a href="https://api.whatsapp.com/send/?phone=01015244493&text&type=phone_number&app_absent=0" target="_blank" class="icon twitter">
                          <div class="tooltip">Whatsapp</div>
                          <span><i class="fa fa-whatsapp"></i></span>
                        </a>
                        <a href="https://www.instagram.com/conclinic.business/" target="_blank" class="icon instagram">
                          <div class="tooltip">Instagram</div>
                          <span><i class="fa fa-instagram"></i></span>
                        </a>
                        <a href="./" class="icon github" target="_blank">
                          <div class="tooltip">Website</div>
                          <span><i class="fa fa-link"></i></span>
                        </a>
                     
                      </div>
                </div>


            </div>
        </div>
        <!-- <div class="container">
            <ngb-carousel>
                <ng-template ngbSlide *ngFor="let image of images">
                  <div class="wrapper">
                    <img [src]="image.src" alt="Random first slide">
                  </div>
                  <div class="carousel-caption">
                    <h3>{{ image.title }}</h3>
                    <p>{{ image.short }}</p>
                  </div>
                </ng-template>
              </ngb-carousel>
        </div> -->




    </section>
</div>