<div *ngIf="showProgress">
    <loading></loading>
</div>

<form *ngIf="active" novalidate class="form-horizontal" name="changePasswordForm"  #changePasswordForm="ngForm">
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">{{ 'ChangePassword' | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="row alert alert-warning" >
            <!--كلمة المرور يجب أن لا تقل عن ستة أحرف وأن تحتوي علي حروف وأرقام-->
           {{ 'PasswordCriteria' | translate}}
        </div>
        <div class="row">
            <div class="form-group">
                <label for="txtPassword" class="col-md-3 col-sm-4 control-label">{{ 'CurrentPassword' | translate}}</label>
                <div class="col-md-9 col-sm-8">
                    <input type="password"  pattern="(?=.*\d)(?=.*[a-z]).{6,}"  class="form-control" validateEqual="txtConfirmPassword" reverse="true" name="txtPassword" id="txtPassword" [(ngModel)]='model.oldPassword' #txtPassword="ngModel" required>
                    <div [hidden]="txtPassword.valid || txtPassword.pristine" class="alert alert-danger">
                        {{ 'RequiredField' | translate}}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="txtNewPassword" class="col-md-3 col-sm-4 control-label">{{ 'NewPassword' | translate}}</label>
                <div class="col-md-9 col-sm-8">
                    <input type="password"  pattern="(?=.*\d)(?=.*[a-z]).{6,}"  class="form-control"  name="txtNewPassword" id="txtNewPassword" [(ngModel)]='model.newPassword' #txtNewPassword="ngModel" required>
                    <div [hidden]="txtNewPassword.valid || txtNewPassword.pristine" class="alert alert-danger">
                        {{ 'RequiredField' | translate}}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="txtConfirmPassword" class="col-md-3 col-sm-4 control-label">{{ 'ConfirmPassword' | translate}}</label>
                <div class="col-md-9 col-sm-8">
                    <input type="password"  pattern="(?=.*\d)(?=.*[a-z]).{6,}"  class="form-control"  name="txtConfirmPassword" id="txtConfirmPassword" [(ngModel)]='model.confirmPassword' #txtConfirmPassword="ngModel" required>
                    <div [hidden]="txtConfirmPassword.valid || txtConfirmPassword.pristine" class="alert alert-danger">
                        {{ 'RequiredField' | translate}}
                                                <!--تأكيد كلمة المرور غير متطابقة مع كلمة المرور الجديدة-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary"  disabled="{{ enableMyAccountBtn ? '' :'disabled'}}" (click)="changePassword()" [disabled]="!changePasswordForm.form.valid">{{ 'ChangePassword' | translate}}
</button>
        <button type="button" #btnCloseChangePasswordPopup class="btn btn-primary" data-dismiss="modal">{{ 'Cancel' | translate}}</button>
    </div>
</form>