<div class="what-we-do text-center">
  <div class="container">
    <h2>{{"whatWeDo.heading"| translate}}</h2>
    <p class="desc">{{"whatWeDo.p"| translate}}</p>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="my-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-notebook"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#00bfd8"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"
            />
            <line x1="13" y1="8" x2="15" y2="8" />
            <line x1="13" y1="12" x2="15" y2="12" />
          </svg>
        </div>
        <div class="my-caption">
          <h5>{{"whatWeDo.booking"| translate}}</h5>
          <p>
            {{"whatWeDo.desc3"| translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="my-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-ambulance"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#00bfd8"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="7" cy="17" r="2" />
            <circle cx="17" cy="17" r="2" />
            <path
              d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"
            />
            <path d="M6 10h4m-2 -2v4" />
          </svg>
        </div>
        <div class="my-caption">
          <h5>{{"whatWeDo.emergency"| translate}}</h5>
          <p>
            {{"whatWeDo.desc2"| translate}}
          </p>
        </div>
      </div>
      
      <div class="col-lg-3 col-md-6">
        <div class="my-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-disabled"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#00bfd8"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="11" cy="5" r="2" />
            <polyline points="11 7 11 15 15 15 19 20" />
            <line x1="11" y1="11" x2="16" y2="11" />
            <path d="M7 11.5a5 5 0 1 0 6 7.5" />
          </svg>
        </div>
        <div class="my-caption">
          <h5>{{"whatWeDo.treatment"| translate}}</h5>
          <p>
            {{"whatWeDo.desc4"| translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="my-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-dna"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#00bfd8"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle transform="rotate(-45 12 12)" cx="12" cy="12" r="4" />
            <path d="M9.172 20.485a4 4 0 1 0 -5.657 -5.657" />
            <path d="M14.828 3.515a4 4 0 1 0 5.657 5.657" />
          </svg>
        </div>
        <div class="my-caption">
          <h5>{{"whatWeDo.dna"| translate}}</h5>
          <p>
            {{"whatWeDo.desc"| translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
