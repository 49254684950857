<section class="statistics text-center">
    <div class="container">
        <div class="row stat-holder">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-hospital" width="100" height="100" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="3" y1="21" x2="21" y2="21" />
                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                    <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                    <line x1="10" y1="9" x2="14" y2="9" />
                    <line x1="12" y1="7" x2="12" y2="11" />
                  </svg>
                <h3>768</h3>
                <span class="text-uppercase">{{"statistics.rooms"| translate}}</span>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-disabled-2" width="100" height="100" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="17" cy="6" r="2" />
                    <path d="M9 11a5 5 0 1 0 3.95 7.95" />
                    <path d="M19 20l-4 -5h-4l3 -5l-4 -3l-4 1" />
                  </svg>
                <h3>120</h3>
                <span class="text-uppercase">{{"statistics.chair"| translate}}</span>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ambulance" width="100" height="100" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="7" cy="17" r="2" />
                    <circle cx="17" cy="17" r="2" />
                    <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />
                    <path d="M6 10h4m-2 -2v4" />
                  </svg>
                <h3>8,300</h3>
                <span class="text-uppercase">{{"statistics.car"| translate}}</span>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart" width="100" height="100" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                  </svg>
                <h3>32</h3>
                <span class="text-uppercase">{{"statistics.saved"| translate}}</span>
            </div>
        </div>
    </div>
</section>