import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'upper-nav',
  templateUrl: './upper-nav.component.html',
  styleUrls: ['./upper-nav.component.scss']
})
export class UpperNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
