
<div class="form-horizontal">    
    
    <div class="form-group">
        <label for="txtWorkPhone" class="control-label col-md-2">الهاتف </label>
        <div class="col-md-2">
            <input type="number" class="form-control" id="txtWorkPhone" name="txtWorkPhone" [(ngModel)]='contact.businessPhone' (ngModelChange)="raiseChanges()" >
        </div>    
    </div>
    <div class="form-group">
        <label for="txtFax" class="control-label col-md-2">الفاكس</label>
        <div class="col-md-2">
            <input type="number" class="form-control" id="txtFax" name="txtFax" [(ngModel)]='contact.fax' (ngModelChange)="raiseChanges()">
        </div>
    </div>

    
</div>
