<div class="row">
    <div class="col-md-4 nopadding">
        <div class="nopadding treeBorder" style="height:200px;">
            <p-tree [value]="treeList | translateObjects  : null : null : lstToTranslated" selectionMode="single" 
                    [(selection)]="selectedGroup" (onNodeSelect)="nodeSelect($event)">
                <ng-template let-node pTemplate="{{groupType}}">
                    <span class="nav-icon"><i class="fa fa-cubes"></i></span>
                    &nbsp;
                    {{selectedLang === 'ar' ?  node.label : node.labelTranslation}}
                </ng-template>
            </p-tree>
        </div>
    </div>
    <div class="checkboxListContainer col-md-4 nopadding">
        <input type="search" [placeholder]="'Search' | translate"
               class="form-control" name="txtSearch" id="txtSearch" [(ngModel)]='filterString' />
        <div class="checkboxListScrollArea">
            <table>
                <tr *ngFor="let item of leafList | filterByName:filterString">
                    <td>
                        <input type="checkbox" name="chkBoxItem" value="{{item.id}}"
                               [checked]="selectedItemsIDs.indexOf(item.id) >= 0"
                               (change)="updateSelectedItems(item.id, $event)" />
                        {{ (selectedLang === 'ar') ?  item.name : item.nameTranslation}}
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="checkboxListContainer col-md-4 nopadding">
        <input [placeholder]="'SelectedItems' | translate" disabled  class="form-control text-center" />
        <div class="checkboxListScrollArea">
            <table>
                <tr *ngFor="let item of selectedItems">
                    <td>
                        <img src="assets/images/DeleteRed.png" class="pointer" (click)="deleteItem(item.id)" />&nbsp;
                        {{selectedLang === 'ar' ?  item.name : item.nameTranslation}}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>