<div class="padding5">

    <div class="row">
        <div class="col-md-4 nopadding">
            <input type="search" placeholder="بحث..." class="form-control" />
        </div>
        <div class="col-md-8 text-left nopadding">
            <button type="submit" class="btn btn-primary" routerLink="/projectSearch">بحث عن مشروع مطابق</button>
        </div>
    </div>
    <div class="padding2"></div>
    <table class="table table-responsive table-hover">
        <thead class="alert alert-info">
            <tr>
                <th>المشروع</th>
                <th>تاريخ بداية المشاركة</th>
                <th>تاريخ انتهاء المشاركة</th>
                <th>سبب المشاركة</th>                
                <th>نوع المشاركة</th>               
                <th>عدد ساعات المشاركة</th>                               
                <th>تعديل</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>مشروع 1</td>
                <td>1/10/2016</td>
                <td>30/10/2016</td>
                <td>سبب 1</td>
                <td>منفردا</td>
                <td>20</td>                          
                <td><a routerLink="/projectBasic">تعديل</a></td>
            </tr>
            <tr>
                <td>مشروع 2</td>
                <td>1/9/2016</td>
                <td>30/9/2016</td>
                <td>سبب 2</td>
                <td>ضمن فريق</td>
                <td>20</td>                
                <td><a routerLink="/projectBasic">تعديل</a></td>
            </tr>
            
        </tbody>
    </table>

</div>