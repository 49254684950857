<nav dir="{{ 'dir' | translate }}" class="navbar navbar-expand-lg navbar-light">
  <div class="container">
    <a class="navbar-brand" href="#" style="height: 80px;">
      <img src="assets/img/{{ 'navBar.logo' | translate }}.png" width="120" height="60" alt="Con Clinic" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navbarSupportedContent"
      aria-expanded="false" aria-label="Toggle navigation" (click)="showNavbarSupportedContent()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse my_collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" routerLink="">{{ "navBar.Home" | translate }}
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="about">{{ "navBar.About" | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="blogs">{{ "navBar.blog" | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="features">{{ "navBar.features" | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="departments">{{ "navBar.departments" | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="contact">{{ "navBar.Contact" | translate }}</a>
        </li>
      </ul>
      <div class="sign lang-switch">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ "navBar.lng" | translate }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <span class="dropdown-item" (click)="changeLang('en')">
              <img src="assets/img/enFlag.png" alt="" width="25px" height="25px" />
              <span class="txt"> EN </span>  
            </span>
            <span class="dropdown-item" (click)="changeLang('ar')">
              <img src="assets/img/arFlag.png" alt="" width="25px" height="25px" />
              <span class="txt"> AR </span>  
            </span>
          </div>

        </div>

        <button routerLink="layout-login/doctor" class="btn  login">
          {{ "navBar.login" | translate }}
        </button>
      </div>
    </div>
  </div>
</nav>
<!-- Search Popup Component -->
<search></search>