<div *ngIf="showProgress">
    <loading></loading>
</div>

<div class="row well">
    <div class="col-md-12">
        <div class="tabbable-panel">
            <div  class="tabbable-line">
                <ul class="nav nav-tabs">
                    <li class="active"><a href="#userGroups" data-toggle="tab" aria-expanded="true" [class.disabled]="!isClinicAdmin ? true : null">{{ 'Groups' | translate}}</a></li>
                    <li><a href="#users" data-toggle="tab" aria-expanded="true" [class.disabled]="!isClinicAdmin ? true : null">{{ 'Users' | translate}}</a></li>
                </ul>

            </div>

        </div>
        <div id="myTabContent" class="tab-content">
            <div class="tab-pane fade active in" id="userGroups">
                <user-groups [userGroupsWrapper]="userGroupsWrapper" (onModelUpdated)="updateModel()" [isClinicAdmin]="isClinicAdmin"></user-groups>
            </div>
            <div class="tab-pane fade" id="users">
                <user-permissions [userGroupsWrapper]="userGroupsWrapper" (onModelUpdated)="updateModel()" [isClinicAdmin]="isClinicAdmin"></user-permissions>
            </div>

        </div>
    </div>
</div>


