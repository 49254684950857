
export class PermissionNames {

    fullControl: string = 'fullControl';
    view: string = 'view';
    add: string = 'add';
    edit: string = 'edit';
    activate: string = 'activate';
    allow: string = 'allow';

}