<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-mg-12 footer-about">
        <h3 class="text-uppercase"> {{ "footer.about" | translate }} </h3>
        <p class="first">{{ "footer.firstP" | translate }}</p>
        <p class="second">{{ "footer.secondP" | translate }}</p>
      </div>
      <div class="col-lg-4 col-md-12 left-footer">
        <h3 class="text-uppercase"> {{ "footer.contact" | translate }}</h3>
        <ul class="list-unstyled">
          <li class="d-flex">
            <img src="assets/img/white-loc.png" alt="" />
            <span>
              6th of October, 4th District, 8th Neighbourhood, <br />
              Al-Yasmeen Street , Plot 1880
            </span>
          </li>
          <li class="d-flex">
            <img src="assets/img/white-env.png" alt="" />
            <span> info@conclinic.com </span>
          </li>
          <li class="d-flex">
            <img src="assets/img/white-phone.png" alt="" />
            <span>  01501552880 2+</span>
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-12 right-footer d-flex justify-content-between">
        <div class="links-holder">
          <h3 class="text-uppercase">{{ "footer.NAVIGATION" | translate }}</h3>
          <ul class="list-unstyled">
            <li><a routerLink="">{{ "footer.homeLink" | translate }}</a></li>
            <li><a routerLink="about">{{ "footer.aboutLink" | translate }}</a></li>
            <li><a routerLink="blogs">{{ "navBar.blog" | translate }}</a></li>
            <li><a routerLink="departments">{{ "footer.departmentLink" | translate }}</a></li>
            <li><a routerLink="contact">{{ "footer.contactLink" | translate }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="
      copyRights
      container
      position-absolute
      d-flex
      justify-content-between
      align-items-center
    ">
    <span>{{ "footer.copyRights" | translate }}</span>
    <div class="social">
      <a target="_blank" href="https://www.facebook.com/conclinic.business">
        <img src="assets/img/upperNav/social/face.png" alt="" />
      </a>
      <a target="_blank" href="https://www.instagram.com/conclinic.business">
        <img src="assets/img/upperNav/social/insta.png" alt="" />
      </a>

      <a target="_blank" href="https://www.linkedin.com/company/conclinic/">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin" width="24"
          height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#212529" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="16" height="16" rx="2" />
          <line x1="8" y1="11" x2="8" y2="16" />
          <line x1="8" y1="8" x2="8" y2="8.01" />
          <line x1="12" y1="16" x2="12" y2="11" />
          <path d="M16 16v-3a2 2 0 0 0 -4 0" />
        </svg>
      </a>


    </div>
  </div>
</footer>